import type { CustomerSubscriptionStatus } from '@orus.eu/backend/src/views/subscriptions/subscription-view'
import { Avatar, Chip, type ChipVariant, type CompoundIconName } from '@orus.eu/pharaoh'
import { memo } from 'react'

export const SubscriptionStatusChip = memo<{ status: CustomerSubscriptionStatus }>(function SubscriptionStatusChip({
  status,
}) {
  return (
    <Chip size="small" variant={chipVariant[status]} avatar={<Avatar icon={chipIcon[status]} />}>
      {chipLabel[status]}
    </Chip>
  )
})

const chipVariant: Record<CustomerSubscriptionStatus, ChipVariant> = {
  'in-progress': 'informal',
  exclusion: 'warning',
}

const chipIcon: Record<CustomerSubscriptionStatus, CompoundIconName> = {
  'in-progress': 'hourglass-regular',
  exclusion: 'ban-regular',
}

const chipLabel: Record<CustomerSubscriptionStatus, string> = {
  'in-progress': 'A signer',
  exclusion: 'Exclusion',
}
